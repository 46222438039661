<template>
  <div>
    <!-- cambiar esto-->
    <v-simple-table class="">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("device_name") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("printer_target") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("width") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("description") }}
            </th>
            <th style="width: 90px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="font-weight-regular">
                <span>{{ item.device_name }}</span>
              </td>
              <td class="font-weight-regular">
                <span>{{ $t(item.printer_target) }}</span>
              </td>
              <td class="font-weight-regular">
                <span>{{ item.width }}</span>
              </td>
              <td class="font-weight-regular">
                <span>{{ item.description }}</span>
              </td>
              <td>
                <v-btn
                  @click="openModal(item, index)"
                  x-small
                  icon
                  :title="$t('edit')"
                  color="primary"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="deleteItem(index)"
                  v-if="!item.is_default_create"
                  x-small
                  icon
                  :title="$t('delete')"
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end my-3">
      <v-btn
        small
        color="primary"
        :title="$t('Agregar Impresora')"
        @click="openModal()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Impresora") }}</v-btn
      >
    </div>
    <modal
      ref="modal"
      @update:item="editItem"
      @create:item="addItem"
      :items="items"
    />
  </div>
</template>

<script>
import MixinTables from "./MixinTables";
export default {
  mixins: [MixinTables],
};
</script>
