<template>
  <div>
    <AppMenu />
    <BaseBreadcrumb
      :title="$t(title)"
      :help="help"
      :breadcrumbs="breadcrumbs"
      :tutorial="tutorial"
      module="cash-register"
    >
    </BaseBreadcrumb>
    <div class="py-2 mt-4 pl-3 pr-3">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex flex-column justify-center">
            <Form
              @change:item="updateItemFromResponse"
              :item="item"
              :module-actions="moduleActions"
              ref="form"
              :url-save="urlSave"
              :url-get="urlGet"
            ></Form>
            <v-container fluid class="d-flex flex-column justify-center">
            </v-container>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="px-2"> </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import AppMenu from "../menu/AppMenu.vue";
import Form from "./CashRegisterUpdateForm.vue";

export default {
  components: { Form, AppMenu },
  data() {
    return {
      title: "create_cash-register",
      urlGet: "api/v1/cash-register",
      urlSave: "api/v1/cash-register",
      pathBase: "/billing/cash-register",
      pathCreate: "/billing/cash-register/create",
      moduleActions: [],
      item: null,
      title: "Caja: ",
      help: null,
      breadcrumbs: null,
      tutorial: null,
      pos: null,
      installation: null,
    };
  },
  mounted() {
    if (this.$store.getters.installation) {
      this.installation = this.$store.getters.installation;
    }
  },
  watch: {
    "$store.getters.installation"(value) {
      this.installation = value;
    },
    installation(value) {
      this.getPosInstallation();
    },
  },
  methods: {
    updateItemFromResponse(res) {
      this.item = { ...res.item };
    },
    itemToTitle() {
      this.title = "Caja: " + this.item.name;
    },
    async getPosInstallation() {
      if (!this.installation) {
        this.installation = await this.$db.adapter.getInstallation();
        this.$store.dispatch("setInstallation", this.installation);
      }
      if (this.installation) {
        const cashRegister = await this.$db.getCashRegister(
          this.installation.cash_id
        );
        this.pos = { id: cashRegister.id, name: cashRegister.name };
        this.getCashRegister();
      }
    },
    getCashRegister() {
      this.$http.post(this.urlGet + "/" + this.pos.id).then((res) => {
        if (res.success) {
          this.item = res.item;
          this.itemToTitle();
        }
      });
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
  },
};
</script>
