<template>
  <div>
    <!-- cambiar esto-->
    <v-simple-table class="">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("category") }}
            </th>
            <th class="text-left body-2 font-weight-medium">
              {{ $t("printer_name") }}
            </th>
            <th style="width: 90px"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="font-weight-regular">
                <span>{{ item.category.name }}</span>
              </td>
              <td class="font-weight-regular">
                <span>{{ item.printer_name }}</span>
              </td>
              <td>
                <v-btn
                  @click="openModal(item, index)"
                  x-small
                  icon
                  :title="$t('edit')"
                  color="primary"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="deleteItem(index)"
                  v-if="!item.is_default_create"
                  x-small
                  icon
                  :title="$t('delete')"
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex justify-end my-3">
      <v-btn
        small
        color="primary"
        :title="$t('Agregar Categoría')"
        @click="openModal()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Categoría") }}</v-btn
      >
    </div>
    <modal
      ref="modal"
      @update:item="editItem"
      @create:item="addItem"
      :form="form"
      :itemForm="item"
    />
  </div>
</template>

<script>
import Modal from "./ConfigCategoriesModal.vue";
export default {
  components: { Modal },
  props: ["form", "item"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    items(value) {
      this.$emit("change:items", value);
    },
    item(value) {
      if (value && value.config_categories_by_printer) {
        this.items = JSON.parse(
          JSON.stringify(value.config_categories_by_printer)
        );
      }
    },
    "form.printers"(value) {
      if (value) {
        this.verifyIfExistPrintName(value);
      }
    },
  },
  methods: {
    openModal(item = null, index = null) {
      this.$refs.modal.renderItem(item, index);
    },
    deleteItem(index) {
      const removed = this.items.filter((item, i) => i !== index);
      this.items = removed;
    },
    addItem(item) {
      this.items.push(item);
    },
    freshData() {
      this.items = JSON.parse(JSON.stringify(this.items));
    },

    verifyIfExistPrintName(printers) {
      let indexRemove = [];
      this.items.forEach((category, index) => {
        let encontrar = false;
        for (let printer of printers) {
          if (printer.device_name === category.printer_name) {
            encontrar = true;
          }
        }
        if (!encontrar) {
          indexRemove.push(index);
        }
      });
      this.deleteCategoriesWithPrinterNameInvalid(indexRemove);
    },
    deleteCategoriesWithPrinterNameInvalid(indexRemove) {
      for (let value of indexRemove) {
        this.items.splice(value, 1);
      }
    },
    editItem(item, index) {
      this.items[index] = item;
      this.freshData();
    },
  },
};
</script>
