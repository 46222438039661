<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("device_name")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right"
          v-if="item.device_name"
          >{{ item.device_name }}
        </v-col>
        <v-col cols="5" class="font-weight-medium subtitle-2 text-right">{{
          $t("printer_target")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right"
          v-if="item.printer_target"
          >{{ $t(item.printer_target) }}</v-col
        >
        <v-col cols="12" class="font-weight-medium subtitle-2 text-center my-1" v-if="item.description">
          <strong>{{ $t("description") }}</strong>
        </v-col>
        <v-col
          cols="12"
          class="font-weight-regular subtitle-2"
          v-if="item.description"
          >{{ item.description }}</v-col
        >
        <v-col cols="12">
          <div class="d-flex justify-space-around my-3">
            <v-btn small @click="openModal(item, index)" icon color="primary"
              ><v-icon>mdi-pencil</v-icon> {{ $t("Editar") }}
            </v-btn>
            <v-btn
              small
              @click="deleteItem(index)"
              icon
              color="error"
              v-if="!item.is_default_create"
              ><v-icon>mdi-delete</v-icon> {{ $t("Eliminar") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <div class="d-flex justify-end mt-4 mb-4">
      <v-btn
        block
        small
        color="primary"
        :title="$t('Agregar Impresora')"
        @click="openModal()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Impresora") }}</v-btn
      >
    </div>
    <modal ref="modal" @update:item="editItem" @create:item="addItem" :items="items"/>
  </div>
</template>

<script>
import MixinTables from "./MixinTables";
export default {
  mixins: [MixinTables],
};
</script>

