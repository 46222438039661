<template>
  <v-row no-gutters>
    <v-col cols="12" class="panel-left-print-config">
      <v-sheet outlined>
        <div class="d-flex flex-column justify-center">
          <ValidationObserver v-slot="{ invalid, errors }" ref="form">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card elevation="0" class="px-2 py-4 no-shadow">
                    <v-row no-gutters>
                      <v-col
                        class="px-1 input-form-print_order_accepted"
                        cols="12"
                        md="6"
                      >
                        <ng-check
                          v-model="form.print_order_accepted"
                          label="print_order_accepted"
                          :rules="rules['print_order_accepted']"
                        ></ng-check>
                      </v-col>
                      <v-col
                        class="px-1 input-form-print_invoice_order_aceppted"
                        cols="12"
                        md="6"
                      >
                        <ng-check
                          v-model="form.print_invoice_order_accepted"
                          label="print_invoice_order_aceppted"
                          :rules="rules['print_invoice_order_aceppted']"
                        ></ng-check>
                      </v-col>
                      <v-col
                        class="px-1 input-form-printers"
                        cols="12"
                        md="12"
                        :class="$store.getters.mobile ? 'text-center' : ''"
                      >
                        <span class="text-h5 mx-2">{{ $t("printers") }}</span>
                        <PrintersMobile
                          v-if="$store.getters.mobile"
                          :form="form"
                          :item="item"
                          @change:items="freshPrinters"
                        />
                        <PrintersDesktop
                          v-if="!$store.getters.mobile"
                          :form="form"
                          :item="item"
                          @change:items="freshPrinters"
                        /> </v-col
                      ><v-col
                        class="px-1 input-form-config_categories_by_printer"
                        cols="12"
                        md="12"
                        :class="$store.getters.mobile ? 'text-center' : ''"
                      >
                        <span class="text-h5 mx-2">{{
                          $t("config_categories_by_printer")
                        }}</span>
                        <ConfigCategoriesMobile
                          v-if="$store.getters.mobile"
                          :form="form"
                          :item="item"
                          @change:items="freshConfigCategories"
                        />
                        <ConfigCategoriesDesktop
                          v-if="!$store.getters.mobile"
                          :form="form"
                          :item="item"
                          @change:items="freshConfigCategories"
                        />
                      </v-col>
                    </v-row>
                    <default-errors :errors="errors"></default-errors>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="py-2 mt-2 text-right">
                          <v-btn
                            id="btn-print-config-update"
                            class="mr-2"
                            small
                            :block="$store.getters.mobile"
                            :class="$store.getters.mobile ? 'mb-2' : ''"
                            v-if="item"
                            :disabled="invalid"
                            :color="'primary'"
                            :title="$t('update')"
                            @click="executeUpdate()"
                          >
                            <v-icon small>mdi-content-save</v-icon>
                            {{ $t("update") }}
                          </v-btn>
                          <v-btn
                            id="btn-print-config-create"
                            class="mr-2"
                            small
                            :block="$store.getters.mobile"
                            :class="$store.getters.mobile ? 'mb-2' : ''"
                            v-if="!item"
                            :disabled="invalid"
                            :color="'primary'"
                            :title="$t('save_and_edit')"
                            @click="executeCreate()"
                          >
                            <v-icon small>mdi-content-save</v-icon>
                            {{ $t("save_and_edit") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </div>
      </v-sheet>
    </v-col>
    <v-col cols="12" class="pl-0 pl-md-3 mt-3 mt-md-0 panel-right-print-config">
    </v-col>
  </v-row>
</template>
<script>
import MixinForm from "./MixinForm";
import PrintersMobile from "./PrintersMobile.vue";
import PrintersDesktop from "./PrintersDesktop.vue";
import ConfigCategoriesMobile from "./ConfigCategoriesMobile.vue";
import ConfigCategoriesDesktop from "./ConfigCategoriesDesktop.vue";

export default {
  mixins: [MixinForm],
  components: {
    PrintersMobile,
    PrintersDesktop,
    ConfigCategoriesMobile,
    ConfigCategoriesDesktop,
  },
  props: ["cashRegisterId", "cashRegisterForm"],
  data() {
    return {
      rules: {},
    };
  },

  mounted() {
    this.reset();
    this.setRules();
    this.getConfig();
    if (this.cashRegisterForm) {
      this.form.cash_register = {
        id: this.cashRegisterForm.id,
      };
      this.form.subsidiary = this.cashRegisterForm.subsidiary;
    }
  },
  watch: {
    "cashRegisterForm.subsidiary"(value) {
      if (value) {
        this.form.subsidiary = value;
      }
    },
  },
  methods: {
    clearForm() {
      this.form = {
        subsidiary: null,
        cash_register: null,
        print_order_accepted: null,
        print_invoice_order_aceppted: null,
        printers: null,
        config_categories_by_printer: null,
      };
    },
    setRules() {
      this.rules = {
        subsidiary: "required",
        cash_register: "required",
        print_order_accepted: "",
        print_invoice_order_aceppted: "",
        printers: "required",
        config_categories_by_printer: "",
      };
    },
    getConfig() {
      this.$http.get(this.urlSave + "/cash-register/" + this.cashRegisterId).then(
        (res) => {
          if (res.success) {
            this.item = JSON.parse(JSON.stringify(res.item));
            this.form = JSON.parse(JSON.stringify(res.item));
          }
        }
      );
    },
    getValues() {
      let values = {};
      if (
        !this.item ||
        this.hasChanged(this.item["subsidiary"], this.form["subsidiary"])
      ) {
        values["subsidiary"] = this.form["subsidiary"]
          ? this.form["subsidiary"].id
          : null;
      }
      if (
        !this.item ||
        this.hasChanged(this.item["cash_register"], this.form["cash_register"])
      ) {
        values["cash_register"] = this.form["cash_register"]
          ? this.form["cash_register"].id
          : null;
      }
      if (
        !this.item ||
        this.hasChanged(
          this.item["print_order_accepted"],
          this.form["print_order_accepted"]
        )
      ) {
        values["print_order_accepted"] = this.form["print_order_accepted"];
      }
      if (
        !this.item ||
        this.hasChanged(
          this.item["print_invoice_order_accepted"],
          this.form["print_invoice_order_accepted"]
        )
      ) {
        values["print_invoice_order_accepted"] =
          this.form["print_invoice_order_accepted"];
      }
      if (
        !this.item ||
        this.hasChanged(this.item["printers"], this.form["printers"])
      ) {
        values["printers"] = this.form["printers"];
      }
      if (
        !this.item ||
        this.hasChanged(
          this.item["config_categories_by_printer"],
          this.form["config_categories_by_printer"]
        )
      ) {
        values["config_categories_by_printer"] =
          this.form["config_categories_by_printer"];
      }

      return values;
    },
  },
};
</script>
