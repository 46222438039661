// import NextMixinCan from '@/components/mixins/NextMixinCan';
// import NextMixinApiUpdate from '@/components/mixins/NextMixinApiUpdate';
// import NextMixinApiDelete from '@/components/mixins/NextMixinApiDelete';
import _ from 'lodash';



export default {

  data() {
    return {
      loading: false,
      form: {},
      moduleActions: [],
      item: null,
      title: "create_print-config",
      urlGet: "api/v1/print-config",
      urlSave: "api/v1/print-config",
      pathBase: "/pos/print-config",
      pathCreate: "/pos/print-config/create",
    }
  },

  watch: {
    '$route'(to, from) {
      this.reset();
      this.setRules();
    },
  },
  methods: {
    freshPrinters(items) {
      this.form.printers = JSON.parse(JSON.stringify(items));
    },
    freshConfigCategories(items) {
      this.form.config_categories_by_printer = JSON.parse(JSON.stringify(items));
    },
    cancel() {
      this.$router.go(-1);
    },
    //This method validates whether changes have been made to the form.
    hasChanges() {
      let hasOld = JSON.stringify(this.form).hashCode();
      return hasOld !== this.hashValue;
    },
    //It compares whether two values are different.
    hasChanged(oldValue, newValue) {
      if ((!oldValue && newValue) || (oldValue && !newValue)) {
        return true;
      }
      if (
        newValue &&
        newValue.id &&
        oldValue &&
        oldValue.id &&
        oldValue.id != newValue.id
      ) {
        return true;
      }
      return !_.isEqual(oldValue, newValue);
    },
    async clear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    reset() {
      this.clearForm();
    },
    async validateForm() {
      return await this.$refs.obs.validate();
    },
    loadForm(item) {
      if (item.details) {
        this.prepareDetails(item);
      }
      this.form = { ...item };
      this.hashValue = this.getHashValue(this.form);
    },

    prepareDetails(item) {
      if (item.details) {
        for (const d of item.details) {
          d._editing = false;
        }
      }
    },
    saveRowItem(item) {
      for (let i in this.form.details) {
        if (this.form.details[i].id == item.id) {
          this.form.details[i] = item;
          return;
        }
      }
      this.addItemToDetails(item);
    },
    addItemToDetails(item) {
      if (!this.form['details']) {
        this.form['details'] = []
      }
      this.form['details'].push(item)
    },
    disabledChangeState() {
      if (this.itemCurrentlyBeingEdited()) {
        return true;
      }
      return false;
    },
    itemCurrentlyBeingEdited() {
      if (this.form.details && this.form.details.length > 0) {
        for (let item of this.form.details) {
          if (item._editing) {
            return true;
          }
        }
      }
      return false;
    },
    hasDetails() {
      if (this.form.details && this.form.details.length > 0) {
        return true;
      }
      return false;
    },
    disabledCreate() {
      if (!this.hasDetails() || this.itemCurrentlyBeingEdited()) {
        return true;
      }
      return !this.hasChanges(); // || (this.form.details && this.form.details.length == 0)
    },
    getHashValue(value) {
      return (JSON.stringify(value)).hashCode();
    },
    executeUpdate() {
      const values = this.getValues();
      let id = null;
      this.loading = true;
      if (this.item && this.item.id) {
        id = this.item.id;
      }
      if (id) {
        this.$http.put(this.urlSave + '/' + id, values, true, true).then((response) => {
          this.$emit('change:item', response)
          this.loadForm(JSON.parse(JSON.stringify(response.item)));
          //this.moduleActions = response.module_actions;
          this.loading = false;
        }).catch((e) => {
          this.loading = false;
          console.log(e)
        });
      } else {
        this.$http.post(this.urlSave, values, true, true).then((response) => {
          this.$emit('change:item', response)
          this.loadForm(JSON.parse(JSON.stringify(response.item)));
          //this.moduleActions = response.module_actions;
          this.loading = false;
        }).catch((e) => {
          this.loading = false;
          console.log(e)
        });
      }
    },
    executeCreate() {
      const values = this.getValues();
      //let id = this.$route.params.id;
      this.loading = true;

      this.apiCreate(this.urlSave, values, true, true).then((response) => {
        //console.log(response)
        this.$emit('change:item', response)
        this.loadForm(JSON.parse(JSON.stringify(response.item)));
        //this.moduleActions = response.module_actions;
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        console.log(e)
      });

    },
    // executeDelete() {
    //   this.apiDelete(this.urlGet + '/' + this.item.id, 'Esta seguro que desea eliminar este registro?').then(() => {
    //     this.$router.go(-1);
    //   })
    // },
  }
}
