<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="closeModal()">
    <v-card :key="key ? key : 0">
      <ValidationObserver v-slot="{ invalid }" ref="form2">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("printer") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="closeModal()"
            :title="$t('Cancel')"
            id="btn-modal-cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text class="pb-0" style="max-height: 60vh; overflow-y: auto">
          <v-list class="pt-3 pb-0" v-if="item">
            <v-list-item>
              <v-list-item-content class="py-2">
                <ng-text
                  dense
                  v-model="item.device_name"
                  label="device_name"
                  rules="required"
                ></ng-text>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="py-2">
                <ng-select-options
                  dense
                  :disabled="item.is_default_create"
                  :items="targetOptions"
                  v-model="item.printer_target"
                  label="printer_target"
                  @input="setSelectItem()"
                ></ng-select-options>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="py-2">
                <ng-select-options
                  dense
                  :items="withOptions"
                  v-model="item.width"
                  label="width"
                  rules="required"
                ></ng-select-options>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="py-2">
                <ng-textarea
                  dense
                  v-model="item.description"
                  label="description"
                ></ng-textarea>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            :disabled="invalid"
            color="primary"
            @click="save()"
            :title="$t('Guardar')"
            id="btn-detail-save-content"
          >
            <v-icon>mdi-check</v-icon>{{ $t("apply") }}
          </v-btn>
          <v-btn
            @click="closeModal()"
            :title="$t('cancel')"
            dark
            small
            outlined
            color="grey darken-3"
            id="btn-detail-cancel"
          >
            <v-icon>mdi-close</v-icon>{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["items"],
  data() {
    return {
      key: 0,
      dialog: false,
      item: null,
      index: null,
      targetOptions: [
        {
          id: "principal",
          name: this.$t("principal"),
        },
        {
          id: "comandas",
          name: this.$t("comandas"),
        },
      ],
      withOptions: ["58mm", "80mm", "48mm"],
    };
  },
  watch: {
    "item.printer_target"(value) {
      if (value) {
        this.setSelectItem();
      }
    },
  },
  methods: {
    renderItem(item = null, index = null) {
      this.index = index;
      this.key = index;
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
      } else {
        this.clearForm();
      }
      this.dialog = true;
    },
    closeModal() {
      this.item = null;
      this.dialog = false;
      this.index = null;
    },
    save() {
      if (this.index !== null) {
        this.$emit("update:item", this.getValues(), this.index);
      } else {
        if (!this.IsUniqueName()) {
          alert(this.$t("message_device_name_is_unique"));
          return;
        }
        this.$emit("create:item", this.getValues());
      }
      this.dialog = false;
    },
    clearForm() {
      this.item = {
        device_name: null,
        description: null,
        printer_target: null,
        width: null,
        is_default_create: false,
      };
    },
    transformPrinterTarget() {
      if (typeof this.item.printer_target === "object") {
        return this.item.printer_target ? this.item.printer_target.id : null;
      } else {
        return this.item.printer_target;
      }
    },
    setSelectItem() {
      console.log("setSelectItem");
      if (typeof this.item.printer_target === "string") {
        for (let value of this.targetOptions) {
          if (value.id === this.item.printer_target) {
            this.item.printer_target = value;
          }
        }
      }
    },
    IsUniqueName() {
      let valid_name = true;
      for (let item of this.items) {
        if (
          item.device_name.toLowerCase().trim() ===
          this.item.device_name.toLowerCase().trim()
        ) {
          valid_name = false;
        }
      }
      return valid_name;
    },
    getValues() {
      let values = {};
      values["device_name"] = this.item.device_name;
      values["printer_target"] = "principal";
      if (!this.item.is_default_create) {
        values["printer_target"] = this.transformPrinterTarget();
      }
      values["width"] = this.item.width;
      values["description"] = this.item.description;
      values["is_default_create"] = this.item.is_default_create;
      return values;
    },
  },
};
</script>