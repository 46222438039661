<template>
  <v-row no-gutters>
    <v-col cols="12" class="panel-left-cash-register">
      <v-tabs show-arrows>
        <v-tab class="btn-cash-register-tab">
          {{ $t("general_info") }}
        </v-tab>
        <v-tab v-if="item"> {{ $t("Configuración de impresión") }} </v-tab>
        <v-tab-item>
          <v-sheet outlined>
            <div class="d-flex flex-column justify-center">
              <ValidationObserver v-slot="{ invalid, errors }" ref="form">
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-card elevation="0" class="px-2 py-4 no-shadow">
                        <v-row no-gutters>
                          <PosConfig
                            :config="item ? item.config : []"
                            @update:config="updateConfig"
                          />
                        </v-row>
                        <default-errors :errors="errors"></default-errors>
                        <v-row no-gutters>
                          <v-col cols="12">
                            <div class="py-2 mt-2 text-right">
                              <v-btn
                                id="btn-cash-register-update"
                                class="mr-2"
                                small
                                :block="$store.getters.mobile"
                                :class="$store.getters.mobile ? 'mb-2' : ''"
                                v-if="item"
                                :disabled="invalid"
                                :color="'primary'"
                                :title="$t('update')"
                                @click="executeUpdate()"
                              >
                                <v-icon small>mdi-content-save</v-icon>
                                {{ $t("update") }}
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </ValidationObserver>
            </div>
          </v-sheet>
        </v-tab-item>

        <v-tab-item v-if="item">
          <!--template v-slot:title>
               Configuración de impresión
               </template-->
          <PrintConfig :cashRegisterId="item.id" :cashRegisterForm="form" />
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
    <script>
import MixinForm from "./MixinForm";
import PrintConfig from "./PrintConfigComponents/PrintConfig.vue";
import PosConfig from "./PosConfigComponents/PosConfig.vue";

export default {
  mixins: [MixinForm],
  components: { PrintConfig, PosConfig },
  props: ["item", "urlSave", "urlGet"],
  data() {
    return {
      rules: {},
      form: {},
    };
  },
  mounted() {
    this.setRules();
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.form = {
        subsidiary: null,
        name: null,
        active: null,
        config: {},
      };
    },
    setRules() {
      this.rules = {
        subsidiary: "",
        name: "required|max: 64",
        active: "required",
        config: "",
      };
    },
    executeUpdate() {
      const values = this.getValues();
      this.loading = true;
      let id = this.item.id;
      this.$http
        .put(this.urlSave + "/" + id, values, true, true)
        .then((response) => {
          this.$emit("change:item", response);
          this.loadForm(JSON.parse(JSON.stringify(response.item)));
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    loadForm(item) {
      this.form = { ...item };
    },
    hasChanged(oldValue, newValue) {
      if ((!oldValue && newValue) || (oldValue && !newValue)) {
        return true;
      }
      if (
        newValue &&
        newValue.id &&
        oldValue &&
        oldValue.id &&
        oldValue.id != newValue.id
      ) {
        return true;
      }
      return !_.isEqual(oldValue, newValue);
    },
    getValues() {
      let values = {};
      if (
        !this.item ||
        this.hasChanged(this.item["config"], this.form["config"])
      ) {
        values["config"] = this.form["config"];
      }
      return values;
    },
  },
};
</script>
