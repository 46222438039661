<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-row no-gutters class="mt-4">
        <v-col cols="5" class="font-weight-medium subtitle-2">{{
          $t("category")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right"
          v-if="item.category"
          >{{ item.category.name }}
        </v-col>
        <v-col cols="5" class="font-weight-medium subtitle-2 text-right">{{
          $t("printer_name")
        }}</v-col>
        <v-col
          cols="7"
          class="font-weight-regular subtitle-2 text-right"
          v-if="item.printer_name"
          >{{ item.printer_name }}</v-col
        >
        <v-col cols="12">
          <div class="d-flex justify-space-around my-3">
            <v-btn small @click="openModal(item, index)" icon color="primary"
              ><v-icon>mdi-pencil</v-icon> {{ $t("Editar") }}
            </v-btn>
            <v-btn
              small
              @click="deleteItem(index)"
              icon
              color="error"
              v-if="!item.is_default_create"
              ><v-icon>mdi-delete</v-icon> {{ $t("Eliminar") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <div class="d-flex justify-end mt-4 mb-4">
      <v-btn
        block
        small
        color="primary"
        :title="$t('Agregar Categoría')"
        @click="openModal()"
        ><v-icon>mdi-plus</v-icon> {{ $t("Agregar Categoría") }}</v-btn
      >
    </div>
    <modal
      ref="modal"
      @update:item="editItem"
      @create:item="addItem"
      :form="form"
      :itemForm="item"
    />
  </div>
</template>

<script>
import Modal from "./ConfigCategoriesModal.vue";
export default {
  components: { Modal },
  props: ["form", "item"],
  data() {
    return {
      items: [],
    };
  },
  watch: {
    items(value) {
      this.$emit("change:items", value);
    },
    item(value) {
      if (value && value.config_categories_by_printer) {
        this.items = JSON.parse(
          JSON.stringify(value.config_categories_by_printer)
        );
      }
    },
    "form.printers"(value) {
      if (value) {
        this.verifyIfExistPrintName(value);
      }
    },
  },
  methods: {
    openModal(item = null, index = null) {
      this.$refs.modal.renderItem(item, index);
    },
    deleteItem(index) {
      const removed = this.items.filter((item, i) => i !== index);
      this.items = removed;
    },
    addItem(item) {
      this.items.push(item);
    },
    freshData() {
      this.items = JSON.parse(JSON.stringify(this.items));
    },
    verifyIfExistPrintName(printers) {
      let indexRemove = [];
      this.items.forEach((category, index) => {
        let encontrar = false;
        for (let printer of printers) {
          if (printer.device_name === category.printer_name) {
            encontrar = true;
          }
        }
        if (!encontrar) {
          indexRemove.push(index);
        }
      });
      this.deleteCategoriesWithPrinterNameInvalid(indexRemove);
    },
    deleteCategoriesWithPrinterNameInvalid(indexRemove) {
      for (let value of indexRemove) {
        this.items.splice(value, 1);
      }
    },
    editItem(item, index) {
      this.items[index] = item;
      this.freshData();
    },
  },
};
</script>

