<template>
  <v-card>
    <v-card-title class="justify-center">{{
      $t("configuration")
    }}</v-card-title>
    <v-card-text>
      <template v-for="option in options">
        <SettingItem
          :config="config"
          :option="option"
          :key="'config_' + option.key"
          @change:value="updateConfig"
        />
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import SettingItem from "./SettingItem.vue";
export default {
  components: { SettingItem },
  props: ["config"],
  data() {
    return {
      options: [
        {
          description:
            "Activa la pantalla táctil, esto activa el teclado númerico en pantalla.",
          key: "pos_activate_touchscreen",
          input_type: "switch",
          default: false,
        },
        {
          description: "Activa la opción de cambio de precios en el POS.",
          key: "pos_active_price_change",
          input_type: "switch",
          default: false,

        },
        {
          description: "Activa el agente de impresión en el POS",
          key: "pos_active_print_agent",
          input_type: "switch",
          default: false,
        },
        {
          description:
            "Activa la opción de imprimir el recibo automático, es usado en la pantalla de pagos.",
          key: "pos_active_print_receipt_automatic",
          input_type: "switch",
          default: true,
        },
        {
          description:
            "Activa la opción de impresora remota, por lo regular se usa para la impresión de comandas en cocina.",
          key: "pos_active_print_remote",
          input_type: "switch",
          default: false,

        },
        {
          description:
            "Configura el tamaño de la fuente para el recibo en el POS, colocar valores en el rango de 12-16",
          key: "pos_printer_font_size",
          input_type: "number",
          default: 15
        },
        {
          description:
            "Configura si la tirilla POS se imprime en impresora térmica o de puntos, si esta activo indica que es térmica, si se inactiva indica que es impresora de puntos o de cinta(en esta no salen imágenes), esta configuración aplica para este punto de venta.",
          key: "pos_printer_pos_receipt_is_thermal",
          input_type: "switch",
          default: false
        },
      ],
    };
  },
  methods: {
    updateConfig(value, key) {
      this.$emit("update:config", value, key);
    },
  },
};
</script>