import Modal from "./PrinterModal.vue";
export default {
    components: { Modal },
    props: ["form", "item"],
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        this.createDefaultPrint();
    },
    watch: {
        items(value) {
            this.$emit("change:items", value);
        },
        item(value) {
            if (value) {
                this.items = JSON.parse(JSON.stringify(value.printers));
            }
        },
    },
    methods: {
        openModal(item = null, index = null) {
            this.$refs.modal.renderItem(item, index);
        },
        deleteItem(index) {
            const removed = this.items.filter((item, i) => i !== index);
            this.items = removed;
        },
        createDefaultPrint() {
            let item = {
                device_name: "POS (Cambiar Nombre)",
                printer_target: 'principal',
                description: "",
                width: '80mm',
                is_default_create: true,
            };
            this.items.push(item);
        },
        addItem(item) {
            this.items.push(item);
        },
        freshData() {
            this.items = JSON.parse(JSON.stringify(this.items));
        },
        editItem(item, index) {
            this.items[index] = item;
            this.freshData();
        },
    },
};