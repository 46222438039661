<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="closeModal()">
    <v-card :key="key ? key : 0">
      <ValidationObserver v-slot="{ invalid }" ref="form2">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("category_by_printer") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="closeModal()"
            :title="$t('Cancel')"
            id="btn-modal-cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text class="pb-0" style="max-height: 60vh; overflow-y: auto">
          <v-list class="pt-3 pb-0" v-if="item">
            <v-list-item>
              <v-list-item-content class="py-2">
                <category-product-tree
                  v-model="item.category"
                  label="category"
                  :rules="'required'"
                  url="api/v1/list/category-product"
                ></category-product-tree>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="py-2">
                <ng-select-options
                  dense
                  :items="printersOptions"
                  v-model="item.printer_name"
                  label="printer_name"
                  rules="required"
                ></ng-select-options>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            :disabled="invalid"
            color="primary"
            @click="save()"
            :title="$t('Guardar')"
            id="btn-detail-save-content"
          >
            <v-icon>mdi-check</v-icon>{{ $t("apply") }}
          </v-btn>
          <v-btn
            @click="closeModal()"
            :title="$t('cancel')"
            dark
            small
            outlined
            color="grey darken-3"
            id="btn-detail-cancel"
          >
            <v-icon>mdi-close</v-icon>{{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["form", "itemForm"],
  data() {
    return {
      key: 0,
      dialog: false,
      item: null,
      index: null,
      printersOptions: [],
    };
  },
  watch: {
    "form.printers"(value) {
      if (value) {
        this.createOptions(value);
      }
    },
    itemForm(value) {
      if (value && value.printers) {
        this.createOptions(value.printers);
      }
    },
  },
  methods: {
    renderItem(item = null, index = null) {
      this.index = index;
      this.key = index;
      if (item) {
        this.item = JSON.parse(JSON.stringify(item));
      } else {
        this.clearForm();
      }
      this.dialog = true;
    },
    createOptions(items) {
      this.printersOptions = [];
      for (let item of items) {
        this.printersOptions.push({
          id: item.device_name,
          name: item.device_name,
        });
      }
    },
    closeModal() {
      this.item = null;
      this.dialog = false;
      this.index = null;
    },
    save() {
      if (this.index !== null) {
        this.$emit("update:item", this.getValues(), this.index);
      } else {
        this.$emit("create:item", this.getValues());
      }
      this.dialog = false;
    },

    transformPrinterName() {
      if (typeof this.item.printer_name === "object") {
        return this.item.printer_name.name;
      } else {
        return this.item.printer_name;
      }
    },
    getValues() {
      let values = {};
      values["printer_name"] = this.transformPrinterName();
      values["category"] = this.item.category;
      return values;
    },
    clearForm() {
      this.item = {
        category: null,
        printer_name: null,
      };
    },
  },
};
</script>